<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
// import moment from "moment";

export default {
  page: {
    title: "Halaman",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datatable,
    Pagination,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "id_halaman_master" },
      { width: "100px", label: "Gambar Halaman", name: "-" },
      { width: "100px", label: "Nama Halaman", name: "nama_halaman" },
      { width: "100px", label: "Kategori Halaman", name: "halaman" },
      { width: "100px", label: "Status", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
      { width: "160px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Halaman",
      items: [
        {
          text: "Halaman",
          href: "/dashboard",
        },
        {
          text: "Daftar Halaman",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      // variable Page Table
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "id_halaman", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",

      // parsing data ke table data
      tableData: {
        per_page: 10,
        search: "",
        sort_field: 0,
        sort_order: "desc",
        filter_status: "ENABLE", // untuk filtering data
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
    };
  },
  mounted() {
    let self = this;
    self.getDataTable();
  },
  methods: {
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/halaman"
    ) {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-halaman",
          role_id: self.sessionRoleId
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            let data = response_data_fix.list;
            data.forEach((element) => {
              element.gambar_halaman = element.gambar_halaman != null ? process.env.VUE_APP_BACKEND_URL + element.gambar_halaman : null;
            });
            self.table_data = data;
            self.configPagination(response_data_fix.pagination);
            self.currentTablePage = response_data_fix.pagination.current_page;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    changeStatusData(status, id) {
      let self = this;

      let text_alert = "";
      if (status == "ENABLE") {
        text_alert = "Apakah Anda yakin untuk mengubah status?";
      } else {
        text_alert = "Apakah Anda yakin untuk mengubah status?";
      }

      Swal.fire({
        title: "Perhatian",
        text: text_alert,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "put",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/halaman/" + id + '/status',
            data: {
              id: id,
              status: status,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil diperbarui.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = Swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/halaman/" + id,
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = Swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    toPage: function (url) {
      let self = this;
      self.getDataTable(url);
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">Halaman</div>
              </div>
              <div class="col-md-6">
                <div class="text-end">
                  &nbsp;
                  <!-- <router-link
                    :to="{ name: 'add-halaman' }"
                    class="btn btn-sm btn-success"
                    ><i class="fa fa-plus"></i> Tambah Data</router-link
                  > -->
                </div>
              </div>
              <!-- <div class="col-md-12">
                <hr />
                <div class="row">
                  <div class="form-group col-md-2">
                    <label for="statusTable">Status</label>
                    <select class="form-select" id="statusTable" v-model="tableData.filter_status"
                      @change="getDataTable()">
                      <option value="ENABLE">ENABLE</option>
                      <option value="DISABLE">DISABLE</option>
                    </select>
                  </div>
                </div>
                <hr />
              </div> -->
              <div class="col-md-12 mb-2">
                <div class="row">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <td>Show</td>
                        <td>
                          <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%"
                            v-model="tableData.per_page" @change="getDataTable()">
                            <option value="10" selected>10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                          </select>
                        </td>
                        <td>Entries</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-5">&nbsp;</div>
                  <div class="col-md-3">
                    <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..."
                      v-model="tableData.search" @input="getDataTable()" />
                  </div>
                </div>
                <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                  <tbody>
                    <tr v-if="loadingTable">
                      <td colspan="6">
                        <i class="fas fa-spinner fa-spin"></i> Loading...
                      </td>
                    </tr>
                    <tr v-else-if="table_data == ''">
                      <td class="text-center" colspan="6">Data Tidak Tersedia</td>
                    </tr>
                    <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                      <td class="text-center">
                        <div v-if="key_data + 1 == 10">
                          {{ currentTablePage }}0.
                        </div>
                        <div v-else>
                          {{
                            currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                          }}{{ key_data + 1 }}.
                        </div>
                      </td>
                      <td class="text-center">
                          <template v-if="row_data.gambar_halaman != null">
                            <img  v-bind:src="row_data.gambar_halaman" alt height="100" style="border-radius: 50%;" width="100" />
                          </template>
                          <template v-else>
                            <img src="@/assets/images/default.png" alt height="100" />
                          </template>
                      </td>
                      <td>
                        {{ row_data.nama_halaman }}
                      </td>
                      <td>
                        {{
                          row_data.kategori
                          ? row_data.kategori.nama_kategori_halaman
                          : "-"
                        }}
                      </td>
                      <td class="text-center">
                        <div v-if="row_data.status == 'ENABLE'">
                          <div class="badge badge-pill badge-soft-success font-size-12">
                            ENABLE
                          </div>
                        </div>
                        <div v-else>
                          <div class="badge badge-pill badge-soft-danger font-size-12">
                            DISABLE
                          </div>
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="btn-group">
                          <!-- HALAMAN BERANDA -->
                          <div v-if="row_data.kategori_halaman == '99caa3df-21ff-4967-b0a1-b3e5f6afb365'">
                            <router-link :to="{
                              name: 'edit-halaman-beranda',
                              params: { id: row_data.id_halaman },
                            }" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Edit</router-link>
                          </div>
                          <!-- HALAMAN PROFIL -->
                          <div v-else-if="row_data.kategori_halaman == '99caa4df-8193-4a3a-95e5-af50eb47c3e1'">
                            <router-link :to="{
                              name: 'edit-halaman-profil',
                              params: { id: row_data.id_halaman },
                            }" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Edit</router-link>
                          </div>
                          <!-- HALAMAN SKEMA SERTIFIKASI -->
                          <div v-else-if="row_data.kategori_halaman == '99caa53c-2463-48d9-951e-5fe13febbe0d'">
                            <router-link :to="{
                              name: 'edit-halaman-skema-sertifikasi',
                              params: { id: row_data.id_halaman },
                            }" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Edit</router-link>
                          </div>
                          <!-- HALAMAN INFORMASI -->
                          <div v-else-if="row_data.kategori_halaman == '99caa557-a142-4b4b-a6d3-976a26168b83'">
                            <router-link :to="{
                              name: 'edit-halaman-informasi',
                              params: { id: row_data.id_halaman },
                            }" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Edit</router-link>
                          </div>
                          <!-- HALAMAN GALERI -->
                          <div v-else-if="row_data.kategori_halaman == '99caa569-0c03-48b4-89f5-eac1da0306e5'">
                            <router-link :to="{
                              name: 'edit-halaman-galeri',
                              params: { id: row_data.id_halaman },
                            }" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Edit</router-link>
                          </div>
                          <!-- HALAMAN BERANDA -->
                          <div v-else-if="row_data.kategori_halaman == '99caa58f-60ba-487d-9132-de2041b5a859'">
                            <router-link :to="{
                              name: 'edit-halaman-kontak',
                              params: { id: row_data.id_halaman },
                            }" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Edit</router-link>
                          </div>
                          <!-- HALAMAN BERANDA -->
                          <div v-else-if="row_data.kategori_halaman == '99caa5aa-d488-4433-9ef7-fc742500d0e8'">
                            <router-link :to="{
                              name: 'edit-halaman-pengaduan',
                              params: { id: row_data.id_halaman },
                            }" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Edit</router-link>
                          </div>
                          <!-- HALAMAN BERANDA -->
                          <div v-else-if="row_data.kategori_halaman == '99caa5d0-6252-451d-871c-ee1a02a8e3fc'">
                            <router-link :to="{
                              name: 'edit-halaman-faq',
                              params: { id: row_data.id_halaman },
                            }" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Edit</router-link>
                          </div>

                        </div>
                      </td>
                    </tr>
                  </tbody>
                </datatable>
              </div>
            </div>
            <pagination :pagination="pagination" @to-page="toPage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
