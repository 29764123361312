// import store from "@/state/store";

export default [
  
  {
    path: "/",
    name: "beranda",
    
    component: () => import("./views/website/beranda"),
  },
  {
    path: "/website/profil",
    name: "profil",
    
    component: () => import("./views/website/profil"),
  },
  {
    path: "/website/skema-sertifikasi",
    name: "skema-sertifikasi",
    
    component: () => import("./views/website/skema_sertifikasi"),
  },
  {
    path: "/website/informasi",
    name: "informasi",
    
    component: () => import("./views/website/informasi"),
  },
  {
    path: "/website/galeri",
    name: "galeri",
    
    component: () => import("./views/website/galeri"),
  },
  
  {
    path: "/website/detail-galeri/:id",
    name: "detail-galeri",
    component: () => import("./views/website/galeri_detail"),
  },
  {
    path: "/website/kontak",
    name: "kontak",
    
    component: () => import("./views/website/kontak"),
  },
  {
    path: "/website/pengaduan",
    name: "pengaduan",
    
    component: () => import("./views/website/pengaduan"),
  },
  {
    path: "/website/faq",
    name: "faq",
    
    component: () => import("./views/website/faq"),
  },
  // CMS
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("./views/account/register"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/default"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: { authRequired: true },
    component: () => import("./views/account/profile"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: { authRequired: true },
    component: () => import("./views/account/logout"),
  },

  // routes Master

  // routes master role
  {
    path: "/master/role",
    name: "all-role",
    meta: { authRequired: true },
    component: () => import("./views/master/role/all-role"),
  },
  {
    path: "/master/role/tambah",
    name: "add-role",
    meta: { authRequired: true },
    component: () => import("./views/master/role/add-role"),
  },
  {
    path: "/master/role/edit/:id",
    name: "edit-role",
    meta: { authRequired: true },
    component: () => import("./views/master/role/edit-role"),
  },

  // routes master menu
  {
    path: "/master/menu",
    name: "all-menu",
    meta: { authRequired: true },
    component: () => import("./views/master/menu/all-menu"),
  },
  {
    path: "/master/menu/tambah",
    name: "add-menu",
    meta: { authRequired: true },
    component: () => import("./views/master/menu/add-menu"),
  },
  {
    path: "/master/menu/edit/:id",
    name: "edit-menu",
    meta: { authRequired: true },
    component: () => import("./views/master/menu/edit-menu"),
  },
  {
    path: "/master/menu/ordering",
    name: "ordering-menu",
    meta: { authRequired: true },
    component: () => import("./views/master/menu/ordering-menu"),
  },
  {
    path: "/master/menu/role-menu",
    name: "role-menu",
    meta: { authRequired: true },
    component: () => import("./views/master/menu/role-menu"),
  },

  // routes master user
  {
    path: "/master/user",
    name: "all-user",
    meta: { authRequired: true },
    component: () => import("./views/master/user/all-user"),
  },
  {
    path: "/master/user/tambah",
    name: "add-user",
    meta: { authRequired: true },
    component: () => import("./views/master/user/add-user"),
  },
  {
    path: "/master/user/edit/:id",
    name: "edit-user",
    meta: { authRequired: true },
    component: () => import("./views/master/user/edit-user"),
  },

  // routes master konfigurasi
  {
    path: "/master/konfigurasi",
    name: "all-konfigurasi",
    meta: { authRequired: true },
    component: () => import("./views/master/konfigurasi/all-konfigurasi"),
  },

  // routes tambah master kategori menu
  {
    path: "/master/kategori-menu",
    name: "all-kategori_menu",
    meta: { authRequired: true },
    component: () => import("./views/master/kategori-menu/all-kategori_menu"),
  },
  {
    path: "/master/kategori-menu/tambah",
    name: "add-kategori_menu",
    meta: { authRequired: true },
    component: () => import("./views/master/kategori-menu/add-kategori_menu"),
  },
  {
    path: "/master/kategori-menu/edit/:id",
    name: "edit-kategori_menu",
    meta: { authRequired: true },
    component: () => import("./views/master/kategori-menu/edit-kategori_menu"),
  },

  // routes tambah master halaman
  {
    path: "/master/halaman",
    name: "all-halaman",
    meta: { authRequired: true },
    component: () => import("./views/master/halaman/all-halaman"),
  },
  {
    path: "/master/halaman/tambah",
    name: "add-halaman",
    meta: { authRequired: true },
    component: () => import("./views/master/halaman/add-halaman"),
  },
  {
    path: "/master/halaman/edit-halaman-beranda/:id",
    name: "edit-halaman-beranda",
    meta: { authRequired: true },
    component: () => import("./views/master/halaman/edit-halaman-beranda"),
  },
  {
    path: "/master/halaman/edit-halaman-profil/:id",
    name: "edit-halaman-profil",
    meta: { authRequired: true },
    component: () => import("./views/master/halaman/edit-halaman-profil"),
  },
  
  {
    path: "/master/halaman/edit-halaman-skema-sertifikasi/:id",
    name: "edit-halaman-skema-sertifikasi",
    meta: { authRequired: true },
    component: () => import("./views/master/halaman/edit-halaman-skema-sertifikasi"),
  },

  {
    path: "/master/halaman/edit-halaman-informasi/:id",
    name: "edit-halaman-informasi",
    meta: { authRequired: true },
    component: () => import("./views/master/halaman/edit-halaman-informasi"),
  },
  
  {
    path: "/master/halaman/edit-halaman-galeri/:id",
    name: "edit-halaman-galeri",
    meta: { authRequired: true },
    component: () => import("./views/master/halaman/edit-halaman-galeri"),
  },
  
  {
    path: "/master/halaman/edit-halaman-kontak/:id",
    name: "edit-halaman-kontak",
    meta: { authRequired: true },
    component: () => import("./views/master/halaman/edit-halaman-kontak"),
  },
  {
    path: "/master/halaman/edit-halaman-pengaduan/:id",
    name: "edit-halaman-pengaduan",
    meta: { authRequired: true },
    component: () => import("./views/master/halaman/edit-halaman-pengaduan"),
  },
  
  {
    path: "/master/halaman/edit-halaman-faq/:id",
    name: "edit-halaman-faq",
    meta: { authRequired: true },
    component: () => import("./views/master/halaman/edit-halaman-faq"),
  },
  // routes tambah master kategori halaman
  {
    path: "/master/kategori-halaman",
    name: "all-kategori_halaman",
    meta: { authRequired: true },
    component: () => import("./views/master/kategori-halaman/all-kategori_halaman"),
  },
  {
    path: "/master/kategori-halaman/tambah",
    name: "add-kategori_halaman",
    meta: { authRequired: true },
    component: () => import("./views/master/kategori-halaman/add-kategori_halaman"),
  },
  {
    path: "/master/kategori-halaman/edit/:id",
    name: "edit-kategori_halaman",
    meta: { authRequired: true },
    component: () => import("./views/master/kategori-halaman/edit-kategori_halaman"),
  },

  // routes tambah master kategori halaman
  {
    path: "/master/log-aktivitas",
    name: "all-log_aktivitas",
    meta: { authRequired: true },
    component: () => import("./views/master/log-aktivitas/all-log_aktivitas"),
  },
  
  // (WIP) routes menu dynamic render
  {
    path: "/render",
    name: "render",
    meta: { authRequired: true },
    component: () => import("./views/render"),
  },
];