<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import axios from "axios";
// import Swal from "sweetalert2";

export default {
  page: {
    title: "Tambah Halaman",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Tambah Halaman",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Halaman",
          href: "/master/halaman",
        },
        {
          text: "Tambah Halaman",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,

      // variabel referensi
      optionsMenuParent: [],

      // variable Page
      menu_tipe: "",

      form_menu: false,
      nama_menu: null,
      icon_menu: null,
      link_path_menu: null,
      link_name_menu: null,
      urutan_menu: null,
      parent_menu: [],

      form_label: false,
      nama_label: null,
      urutan_label: null,
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div
                  class="alert alert-danger"
                  v-if="
                    typeof axiosCatchError === 'string' ||
                    axiosCatchError instanceof String
                  "
                >
                  {{ axiosCatchError }}
                </div>
                <div class="alert alert-danger" role="alert" v-else>
                  <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                    <div v-for="(allErrors, idx) in errorArray" :key="idx">
                      <span class="text-danger">{{ allErrors[0] }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-8">
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Nama Halaman</label>
                    <input type="text" class="form-control">
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Banner</label>
                    <input type="file" class="form-control">
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Konten</label>
                    <textarea name="" id="" cols="30" rows="10" class="form-control"></textarea>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Link Terkait</label>
                    <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                      <thead class="bg-dark text-center text-white">
                        <tr>
                          <th>No</th>
                          <th>Nama Link Terkait</th>
                          <th>URL</th>
                          <th>Gambar</th>
                          <th><button class="btn btn-sm btn-success"><i class="fa fa-plus"></i></button></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            <input type="text" class="form-control">
                          </td>
                          <td>
                            <input type="text" class="form-control">
                          </td>
                          <td>
                            <input type="file" class="form-control">
                          </td>
                          <td><button class="btn btn-sm btn-danger"><i class="fa fa-times"></i></button></td>
                        </tr>
                      </tbody>
                  </table>
                  </div>
                </div>
                <div class="col md-4">
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Kategori Halaman</label>
                    <select name="" id="" class="form-control">
                      <option value="">Profil</option>
                      <option value="">Skema Sertifikasi</option>
                      <option value="">Informasi</option>
                      <option value="">Galeri</option>
                      <option value="">Kontak</option>
                    </select>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Gambar Halaman</label>
                    <input type="file" class="form-control">
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-12">
                  <div class="d-grid gap-2">
                    <b-button type="submit" variant="primary"
                      ><i class="fa fa-save"></i> Simpan Halaman</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
