<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Editor from '@tinymce/tinymce-vue'
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  page: {
    title: "Edit Halaman FAQ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Editor
  },
  data() {
    return {
      title: "Edit Halaman FAQ",
      items: [
      {
          text: "Halaman",
          href: "/dashboard",
        },
        {
          text: "Daftar Halaman",
          href: "/master/halaman",
        },
        {
          text: "Edit Halaman",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,

      // variabel referensi
      optionsMenuParent: [],
      id: this.$route.params.id,

      // variable Page
      nama_halaman: null,
      banner_path: null,
      konten: null,
      gambar_halaman_path: null,
      kategori: null,
      meta_content: [],
      editorConfig: {
        height: 500,
        toolbar_mode: "floating",
        plugins: [
          "advlist", "anchor", "autolink", "charmap", "code", "fullscreen", 
          "help", "image", "insertdatetime", "link", "lists", "media", 
          "preview", "searchreplace", "table", "visualblocks", 
        ],
        toolbar: 'cut copy paste pastetext | undo redo | searchreplace | selectall | link unlink anchor | ' +
          'image| table | hr| charmap  |fullscreen | code | preview print | ' +
          'bold italic underline strikethrough subscript superscript | removeformat |'+
          'numlist bullist | outdent indent | blockquote |alignleft aligncenter alignright alignjustify |'+
          'blocks fontfamily fontsize | forecolor backcolor|help |' +
          '',
      },
    };
  },
  mounted() {
    let self = this;
    // get data halaman berdasarkan id
    var config = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/halaman/" + self.id,
      params: {

      },
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
      },
    };
    axios(config)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data;
        if (response_data.meta.code == 200) {
          var data_edit = response_data_fix;
          self.nama_halaman = data_edit.nama_halaman;
          self.banner_path = data_edit.banner;
          self.banner_full = process.env.VUE_APP_BACKEND_URL + data_edit.banner;
          self.konten = data_edit.konten;
          self.gambar_halaman_path = data_edit.gambar_halaman;
          self.gambar_halaman_full = process.env.VUE_APP_BACKEND_URL + data_edit.gambar_halaman;
          self.kategori = data_edit.kategori?.nama_kategori_halaman;

          // META CONTENT
          if (data_edit.meta_content) {
            var json_response_meta_content = JSON.parse(
              data_edit.meta_content
            );
            let clear_data_json_response_meta_content = [];
            $.each(
              json_response_meta_content,
              function (indexInArray, valueOfElement) {
                clear_data_json_response_meta_content.push({
                  pertanyaan: valueOfElement.pertanyaan,
                  jawaban: valueOfElement.jawaban,
                });
              }
            );
            self.meta_content = clear_data_json_response_meta_content;
          }

          Swal.close();
        } else {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.close();
      });
  },
  methods: {
    addMetaContent() {
      this.meta_content.push({
        pertanyaan: "",
        jawaban: "",
      });
    },
    removeMetaContent(index) {
      this.meta_content.splice(index, 1);
    },

    inputFileBanner() {
      let self = this;
      if ($("#formBanner")[0].files[0]) {
        if ($("#formBanner")[0].files[0].size < 2242880) {
          $("#uploadLoadingBanner").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = process.env.VUE_APP_BACKEND_URL;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#formBanner")[0].files[0]);
          var config = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/upload",
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            console.log(response.data.data.path_file);
            var path_file = response.data.data.path_file;
            urlres += path_file;
            $("#uploadLoadingBanner").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
              urlres +
              '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );
            self.banner = path_file;
          });
        } else {
          alert("Max File 2 MB");
        }
      }
    },
    inputFileHalaman() {
      let self = this;
      if ($("#formHalaman")[0].files[0]) {
        if ($("#formHalaman")[0].files[0].size < 2242880) {
          $("#uploadLoadingHalaman").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = process.env.VUE_APP_BACKEND_URL;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#formHalaman")[0].files[0]);
          var config = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/upload",
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            console.log(response.data.data.path_file);
            var path_file = response.data.data.path_file;
            urlres += path_file;
            $("#uploadLoadingHalaman").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
              urlres +
              '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );
            self.gambar_halaman = path_file;
          });
        } else {
          alert("Max File 2 MB");
        }
      }
    },
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "put",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/halaman/" + self.id,
        data: {
          id: self.id,
          nama_halaman: self.nama_halaman,
          banner: self.banner,
          konten: self.konten,
          gambar_halaman: self.gambar_halaman,
          meta_content: JSON.stringify(self.meta_content),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman master halaman segera",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({ name: "all-halaman" });
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' ||
                  axiosCatchError instanceof String
                  ">
                  {{ axiosCatchError }}
                </div>
                <div class="alert alert-danger" role="alert" v-else>
                  <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                    <div v-for="(allErrors, idx) in errorArray" :key="idx">
                      <span class="text-danger">{{ allErrors[0] }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-8">
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Nama Halaman</label>
                    <input type="text" class="form-control" v-model="nama_halaman">
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Banner</label>
                    <input class="form-control" type="file" id="formBanner" v-on:change="inputFileBanner()" />
                    <div class="respond-input-file float-left" id="uploadLoadingBanner"></div>
                    <small class="float-right" style="color: red">*) Max File 2 MB</small>
                    <div v-if="banner_path != null">
                      <div class="position-relative">
                        <img v-bind:src="banner_full" alt="" class="img-thumbnail" style="width: 100%;" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Konten</label>
                    <!-- <textarea name="" id="" cols="30" rows="10" class="form-control" v-model="konten"></textarea> -->
                    <editor v-model="konten"
                        api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0"
                        :init="editorConfig"
                        />
                  </div>
                </div>
                <div class="col md-4">
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Kategori Halaman</label>
                    <input type="text" class="form-control" readonly v-model="kategori">
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Gambar Halaman</label>
                    <input class="form-control" type="file" id="formHalaman" v-on:change="inputFileHalaman()" />
                    <div class="respond-input-file float-left" id="uploadLoadingHalaman"></div>
                    <small class="float-right" style="color: red">*) Max File 2 MB</small>
                    <div v-if="gambar_halaman_path != null">
                      <div class="position-relative">
                        <img v-bind:src="gambar_halaman_full" alt="" class="img-thumbnail" style="height: 100px;" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group col-md-12">
                  <label for="tipe_menu">Daftar FAQ</label>
                  <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                    <thead class="bg-dark text-center text-white">
                      <tr>
                        <th>No</th>
                        <th>Pertanyaan</th>
                        <th>Jawaban</th>
                        <th><button type="button" class="btn btn-sm btn-success" @click="addMetaContent()"><i
                              class="fa fa-plus"></i></button></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="meta_content.length == 0">
                        <td colspan="3">TIdak Ada Data</td>
                      </tr>
                      <tr v-for="(item, index) in meta_content" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>
                          <input type="text" class="form-control" v-model="item.pertanyaan">
                        </td>
                        <td>
                          <!-- <textarea name="" id="" cols="30" rows="10" class="form-control"
                            v-model="item.jawaban"></textarea> -->
                            <editor v-model="item.jawaban"
                                api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0"
                                :init="editorConfig"
                              />
                        </td>
                        <td><button type="button" class="btn btn-sm btn-danger" @click="removeMetaContent(index)"><i
                              class="fa fa-times"></i></button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-12">
                  <div class="d-grid gap-2">
                    <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan Halaman</b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
